import request from "./request";
import crewordRequest from "./crewordRequest";
// 关注/粉丝列表
export function findFocusList(data) {
  return request({
    url: "/user/findFocusList",
    method: "post",
    data,
  });
}

// 关注/取关 return
export function focus(data) {
  return request({
    url: "/user/focus",
    method: "post",
    data,
  });
}

// 作品
export function authorList(data) {
  return request({
    url: "/portfolio/authorList",
    method: "post",
    data,
  });
}

// 作品集榜
export function worksList(data) {
  return request({
    url: "portfolio/worksList",
    method: "post",
    data,
  });
}

// 点赞
export function praise(data) {
  return crewordRequest({
    url: "common/praise",
    method: "post",
    data,
  });
}
