<template>
  <div class="box">
    <div class="outBox">
      <div class="authorDetails leftFlex">
        <div class="back_box">
          <div class="back" @click="$router.go(-1)">
            <i class="el-icon-arrow-left"></i>
            返回
          </div>
        </div>
        <div class="user-info">
          <div class="info">
            <div class="info_box">
              <img
                :src="
                  userInfo.headImg
                    ? userInfo.headImg
                    : require('assets/images/user.png')
                "
                alt=""
              />
              <div class="left">
                <div class="collection">
                  {{ userInfo.nickName }}作品集 (ID:{{ userInfo.number }})
                </div>
                <div class="focus">
                  <span>{{ userInfo.praiseNum }}获赞</span> |
                  <span>{{ userInfo.foucsNum }}关注</span> |
                  <span>{{ userInfo.fansNum }}粉丝</span>
                </div>
              </div>
            </div>

            <div class="Concerns_praise">
              <div class="like_box">
                <div
                  class="Like"
                  @click="isList(userInfo.userId)"
                  v-if="!userInfo.praiseStatus"
                >
                  <img
                    :src="require('assets/images/fabulous-red.png')"
                    alt=""
                  />
                </div>
                <div class="Like" @click="isList(userInfo.userId)" v-else>
                  <img
                    :src="require('assets/images/fabulous-red1.png')"
                    alt=""
                  />
                </div>
              </div>
              <div class="focus_box">
                <div
                  v-if="userInfo.focusType === 0"
                  class="focus_btn Focus"
                  @click="focusClick(userInfo, 1)"
                >
                  关注
                </div>
                <div v-else class="focus_btn" @click="focusClick(userInfo, 2)">
                  <img
                    :src="
                      userInfo.focusType == 1
                        ? require('assets/images/library/icon_1.png')
                        : require('assets/images/library/icon.png')
                    "
                    alt=""
                  />
                  {{ userInfo.focusType == 1 ? "已关注" : "互相关注" }}
                </div>
              </div>
            </div>
          </div>
          <div class="cards">
            <div class="works-number">
              <div class="label">
                <div class="title">作品数量</div>
                <div class="value">
                  {{ userInfo.portfolioNum }}篇作品{{ userInfo.wordTotal }}字
                </div>
              </div>
              <img :src="require('assets/images/works-number.png')" alt="" />
            </div>
            <div
              class="works-rank"
              @click="
                $router.push({
                  name: 'WorksRank',
                  params: {
                    index: '1',
                  },
                })
              "
            >
              <div class="label">
                <div class="title">作品榜</div>
                <div class="value">第{{ userInfo.rank }}位</div>
              </div>
              <img :src="require('assets/images/works-rank.png')" alt="" />
            </div>
            <div
              class="earnings-rank"
              @click="
                $router.push({
                  name: 'AuthorRank',
                  params: {
                    index: '2',
                  },
                })
              "
            >
              <div class="label">
                <div class="title">作者榜</div>
                <div class="value">第{{ userInfo.incomeRank }}位</div>
              </div>
              <img :src="require('assets/images/earnings-rank.png')" alt="" />
            </div>
          </div>
        </div>
        <div class="mian">
          <div class="sidebar">
            <el-menu default-active="2" class="el-menu-vertical-demo">
              <el-menu-item index="0" @click="findAll">全部</el-menu-item>
              <el-submenu
                :index="item.id + ''"
                v-for="item in sidebarArr"
                :key="item.id"
                @click.native="getChild(item)"
              >
                <template slot="title">{{ item.title }}</template>
                <el-menu-item
                  :index="item1.id + ''"
                  v-for="item1 in item.children"
                  @click.native.stop="findListById(item1.id)"
                  :key="item1.id"
                  >{{ item1.title }}</el-menu-item
                >
              </el-submenu>
            </el-menu>
          </div>
          <div class="content">
            <div class="title-option">
              <div class="sort">
                <div
                  class="sort_item"
                  v-for="(item, index) in sortArr"
                  :key="item.id"
                  @click="sortClick(item, index)"
                >
                  <span :class="{ 'is-active': currentSortIndex == index }">{{
                    item.title
                  }}</span>
                  <i
                    class="right-item-icon"
                    :class="item.sort == true ? 'up' : 'down'"
                  ></i>
                </div>
              </div>
            </div>
            <div class="tableContent">
              <div class="table_item" v-for="item in tableData" :key="item.id">
                <img :src="require('assets/images/wps-white.png')" alt="" />

                <div class="main">
                  <div class="title">主标题:{{ item.filename }}</div>
                  <div class="classfiy">{{ item.portfolioType }}</div>
                  <div class="info">
                    <div class="time">
                      创作时间:
                      {{ item.createTime.substring(0, 10) }}
                    </div>
                    <div class="pirce">
                      购买价格: {{ item.price || 0 }}
                      {{
                        item.filetype == 1 && item.setPrice == 0 ? "字符" : "元"
                      }}
                    </div>
                    <div class="pirce">
                      授权价格: {{ item.authorizePrice || 0 }}
                      {{
                        item.filetype == 1 && item.setPrice == 0 ? "字符" : "元"
                      }}
                    </div>
                  </div>
                </div>
                <span
                  :class="[
                    'states',
                    item.status == 2
                      ? 'orange'
                      : item.isParse == 1
                      ? item.status === 1
                        ? 'green'
                        : 'orange'
                      : item.isParse == 0
                      ? 'gray'
                      : item.isParse == 2
                      ? 'eparsed'
                      : item.isParse == 3
                      ? 'hasparse'
                      : '',
                  ]"
                >
                  {{
                    item.status == 2
                      ? "已下架"
                      : item.isParse == 1
                      ? item.status === 1
                        ? "已上架"
                        : "已下架"
                      : item.isParse == 0
                      ? "解析中"
                      : item.isParse == 2
                      ? "解析失败"
                      : item.isParse == 3
                      ? "已存在"
                      : ""
                  }}
                </span>
              </div>
            </div>

            <div class="kong" v-show="tableData.length == 0">
              <img :src="require('assets/images/kong.png')" alt="" />
            </div>

            <el-pagination
              v-show="tableData.length != 0"
              layout="total, prev, pager, next, jumper"
              :pager-count="5"
              :current-page="page"
              :page-size="10"
              @current-change="currentChange"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <div class="right recommendRightList">
        <recommend />
      </div>
    </div>
    <div class="commonFooter_box">
      <commonFooter />
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { userPortfolioInfo } from "api/common";
import { focus, praise } from "api/library";
import { getList, worksTypeList } from "api/copyrightIncome";
import qs from "qs";
import recommend from "components/recommend/recommend.vue";
import commonFooter from "components/footer/commonFooter.vue";
export default {
  name: "authorDetails",
  //import引入的组件需要注入到对象中才能使用
  components: {
    recommend,
    commonFooter,
  },
  data() {
    //这里存放数据
    return {
      userInfo: {},
      tableData: [], //表格数据
      page: 1, //页码
      limit: 10, //一页10条数据
      total: 0, //总条数
      sortArr: [
        //排序数组
        {
          title: "创作时间",
          sort: true, //true 升序 false降序
          id: 1,
        },
        {
          title: "购买/授权价格",
          sort: true,
          id: 2,
        },
        // {
        //   title: "收益",
        //   sort: true,
        //   id: 3,
        // },
      ],
      //排序方式
      sort: "1",
      //升序还是降序 1为升序
      sortType: "1",
      currentSortIndex: 0,
      sidebarArr: [],
      currentId: "",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    async isList(userId) {
      const data = {
        targetId: userId,
        type: 2,
      };

      const res = await praise(data);
      if (res.code == 200) {
        this.userInfo = res.data;
        this.userPortfolio(this.$route.params.userId);
      }
    },

    async userPortfolio(userId) {
      const data = {
        userId,
      };
      const res = await userPortfolioInfo(qs.stringify(data));
      if (res.code == 200) {
        // console.log(res.data);
        this.userInfo = res.data;
      }
    },

    findAll() {
      this.currentId = "";
      this.getTableList();
    },

    // page改变触发
    currentChange(cur) {
      this.page = cur;
      this.getTableList();
    },

    async getChild(item) {
      if (item?.children) {
        return;
      }
      const data = {
        parentId: item?.id,
      };
      const res = await worksTypeList(qs.stringify(data));
      if (res.code == 200) {
        if (!item) {
          this.sidebarArr = res.data;
        } else {
          this.$set(item, "children", res.data.portfolioTypes);
          // console.log(item);
        }
      }
    },

    //获取列表信息
    async getTableList(id) {
      const data = {
        page: this.page,
        limit: this.limit,
        sort: this.sort,
        sortType: this.sortType,
        portfolioType: id,
        userId: this.$route.params.userId,
      };
      const res = await getList(data);
      if (res.code === 200) {
        this.tableData = res.data.data;
        this.total = res.data.total;
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },

    // 取消关注
    async focusClick(item, type) {
      const data = {
        focusId: item.userId,
        type,
      };

      const res = await focus(data);
      if (res.code != 200) {
        this.$message.error(res.message);
        return;
      }

      this.userPortfolio(this.$route.params.userId);
    },

    findListById(id) {
      this.currentId = id;
      this.getTableList(this.currentId);
    },

    sortClick(item, index) {
      // console.log(item);
      item.sort = !item.sort;
      this.currentSortIndex = index;
      if (item.sort) {
        this.sortType = "1";
      } else {
        this.sortType = "2";
      }
      if (item.title == "创作时间") {
        this.sort = "1";
      } else if (item.title == "购买/授权价格") {
        this.sort = "4";
      } else if (item.title == "收益") {
        this.sort = "3";
      }

      this.getTableList();
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.userPortfolio(this.$route.params.userId);
    this.getTableList();
    this.getChild();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style lang="scss" scoped>
.outBox {
  display: flex;
  background: #f9f9f9;
}
.right {
  background-color: #fff;
  flex: 1;
}
.authorDetails {
  width: 936px;
  margin-right: 20px;
  background-color: #fff;
  .back_box {
    border-top: 1px solid #e9ebf2;
    border-bottom: 1px solid #e9ebf2;
    background-color: #fbfcfd;
    height: 30px;
    padding: 0 0 0 10px;
    line-height: 30px;
    .back {
      cursor: pointer;
      font-size: 12px;
    }
  }
  .user-info {
    padding: 15px;

    position: relative;
    .Concerns_praise {
      .focus_box {
        .focus_btn {
          justify-content: center;
          cursor: pointer;
          height: 25px;
          line-height: 25px;
          padding: 0 7px;
          border: 1px solid #666666;
          border-radius: 14px;
          display: flex;
          align-items: center;
          font-size: 12px;
          width: 80px;
          box-sizing: border-box;
          img {
            width: 12px;
            margin-right: 3px;
          }
        }

        .Focus {
          cursor: pointer;
          background-color: #4587ff;
          color: #fff;
          padding: 0 20px;
          border: 0;
        }
      }

      .like_box {
        margin-bottom: 10px;
        .Like {
          text-align: center;
          width: 80px;
          box-sizing: border-box;
          padding: 0 20px;
          cursor: pointer;

          img {
            width: 20px;
          }
        }
      }
    }
    .info {
      width: 100%;
      height: 70px;
      // box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .info_box {
        height: 70px;
        // box-sizing: border-box;
        display: flex;
        align-items: center;
        .left {
          display: flex;
          flex-direction: column;
          margin-left: 25px;
          .collection {
            font-size: 15px;
            color: #999999;
          }
          .focus {
            color: #999999;
            font-size: 12px;
          }
          // align-items: center;
          // justify-content: center;
          > div {
            flex: 1;
          }
          line-height: 35px;
          height: 70px;
        }
        > img {
          width: 63px;
          height: 63px;
          border: 1px solid #e9ebf2;
          border-radius: 50%;
        }
      }
    }
    .cards {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > div {
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // flex: 1;
        width: 49%;
        box-sizing: border-box;
        height: 80px;
        background: #ffffff;
        box-shadow: 0px 2px 7px 0px rgba(11, 41, 85, 0.08);
        border-radius: 8px;
      }
      img {
        width: 32px;
        height: 32px;
      }
      .label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        > div {
          line-height: 1.5;
        }
        .title {
          color: #333333;
          font-size: 14px;
          font-weight: bold;
        }
        .value {
          font-weight: bold;
          font-size: 13px;
          color: #999999;
        }
      }
    }
  }

  .mian {
    display: flex;
    .sidebar {
      width: 170px;
      // height: calc(100vh - 51px);
      height: calc(100vh - 283px);
      // overflow-y: auto;
      border-right: solid 1px #e6e6e6;
      overflow: hidden;

      .el-menu {
        width: 170px;
        .el-menu-item {
          // padding: 0 !important;
          min-width: 170px;
          box-sizing: border-box;
        }
      }

      .el-menu-item.is-active {
        border-right: 3px solid #4587ff;
      }
      ::v-deep .el-menu .el-menu-item,
      el-submenu {
        height: 40px;
        line-height: 40px;
      }
      .item {
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 14px;
        cursor: pointer;
      }
    }
    .content {
      flex: 1;
      overflow: auto;
      height: calc(100vh - 283px);
      position: relative;

      .kong {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .header {
        height: 40px;
        line-height: 40px;
        // text-align: center;
        position: relative;
        background-color: #ecf5ff;
        .upload-new-file {
          margin-left: 10px;
          font-size: 14px;
          display: inline-block;
          color: #4587ff;
          cursor: pointer;
          // width: 100px;
          // display: flex;
          // align-items: center;
          // .add {
          //   font-size: 18px;
          // }
        }
        .authorization {
          color: #40be8a;
          font-size: 14px;
          margin-left: 90px;
          display: inline-block;
          cursor: pointer;
        }

        .refresh {
          color: #4587ff;
          font-size: 14px;
          margin-left: 30px;
          display: inline-block;
          cursor: pointer;
        }
        .works {
          font-size: 15px;
          display: inline-block;
          font-weight: bold;
          cursor: pointer;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateY(-50%) translateX(-50%);
        }
        .num {
          font-size: 12px;
          color: #666666;
          position: absolute;
          right: 10px;
          top: 50%;
          line-height: normal;
          transform: translateY(-50%);
        }
      }
      .title-option {
        // margin: 10px 0;
        position: relative;
        padding: 10px 13px;
        div {
          display: inline-block;
          cursor: pointer;
        }
        img {
          vertical-align: middle;
        }
        // .upload-new-file {
        //   margin-left: 10px;
        //   font-size: 14px;
        //   color: #4587ff;
        // }
        // .authorization {
        //   color: #40be8a;
        //   font-size: 14px;
        //   margin-left: 90px;
        // }
        .sort {
          // transform: translateY(-50%);
          .sort_item {
            margin-right: 20px;
            font-size: 12px;
            color: #666;
            .is-active {
              color: #4587ff;
            }
            i {
              display: inline-block;
              width: 9px;
              height: 13px;
              margin-left: 6px;
            }
            .up {
              @include backgroundGroup("~assets/images/up.png");
            }

            .down {
              @include backgroundGroup("~assets/images/down.png");
            }
          }
        }
      }
      .tableContent {
        padding: 10px;
        // height: calc(100vh - 122px);
        font-size: 14px;
        .table_item {
          position: relative;
          cursor: pointer;
          // padding-top: 10px;
          // box-shadow: 0px 0px 10px 2px #eee;
          border-bottom: 0.0625rem solid #efefef;
          margin: 6px 0;
          padding: 10px 5px;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          // border-bottom: 1px #ccc solid;
          // min-width: 650px;
          white-space: nowrap;
          &:hover {
            background: #ffffff;
            box-shadow: 3px 1px 16px 2px rgba(107, 131, 167, 0.1);
            border-radius: 5px;
          }
          > img {
            width: 60px;
            height: 60px;
          }
          .main {
            width: 80%;
            flex: 1;
          }
          .states {
            display: inline-block;
            width: 50px;
            height: 18px;
            // margin-top: -9px;
            font-size: 12px !important;
            // font-weight: 800;
            text-align: center;
            line-height: 18px;
            color: #999;
            z-index: 1;
            position: absolute;
            left: 11px;
            bottom: 15px;
            &::before {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              display: block;
              width: 100%;
              height: 100%;
              z-index: -1;
            }
          }

          .gray {
            // background: url("~assets/images/rhombus-gray.png") no-repeat;

            color: #999;
          }
          .green {
            // background: url("~assets/images/rhombus-green.png") no-repeat;
            color: #4587ff;
          }
          .orange {
            // background: url("~assets/images/rhombus-orange.png") no-repeat;
            color: orange;
          }
          .eparsed {
            // width: 62px;
            color: rgb(192, 0, 0);
            // background: url("~assets/images/rhombus-red.png") no-repeat;
          }
          .hasparse {
            // width: 75px;
            color: rgb(192, 0, 0);
            // background: url("~assets/images/rhombus-red-long.png") no-repeat;
          }
          .title {
            width: 80%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            .main-title {
              // margin-left: 20px;
              height: 30px;
              line-height: 30px;
            }

            padding-left: 20px;
            height: 30px;
            line-height: 30px;
            font-size: 14px;
            // font-weight: bold;

            // border-left: 3px solid #4587ff;
          }
          .classfiy {
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: 13px;
            // background-image: linear-gradient(to right, #bbb, gray);
            background-color: #4587ff;
            border-radius: 5px;
            color: #fff;
            padding: 5px;
          }
          .info {
            color: gray;
            padding-left: 20px;
            height: 40px;
            line-height: 40px;
            font-size: 13px;

            // color: #333333;
            // img {
            //   width: 50px;
            //   height: 50px;
            //   vertical-align: middle;
            // }
            div:first-child {
              text-align: left;
            }
            div {
              display: inline-block;
              width: 23%;
              text-align: center;
            }
            .more {
              cursor: pointer;
              vertical-align: middle;
            }
          }
        }
        // .table_item:hover {
        //   background-color: #eee;
        // }
      }
    }
  }
}
</style>
